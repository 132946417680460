<template>
  <DataTable :value='storeIncomes.rows' responsiveLayout='scroll' class="p-datatable-sm mt-4">
    <template #empty>
      No hay datos para mostrar
    </template>
    <Column field='date' header='Fecha'></Column>
    <Column field='incomeType' header='Tipo de Ingreso'></Column>
    <Column field='numberDoc' header='Nº Documento'></Column>
    <Column field='client' header='Cliente'></Column>
    <Column field='concept' header='Concepto'></Column>
    <Column field='amount' header='Valor'>
      <template #body="{data}">
        {{ $h.formatCurrency(parseFloat(data.amount), 2) }}
      </template>
    </Column>
    <Column v-if="$can('pharmasan.contabilidad.caja-menor.ver-caja-menor.access')" field='acciones' header='Acciones'>
      <template #body="slotProps">
        <router-link :to="{ name:'pharmasan.contabilidad.caja-menor.ver-caja-menor.ingresos.editar', params: {id:slotProps.data.id} }">
          <button
            type='button'
            class='p-button-rounded p-button-plain p-mr-3'
          >
            <font-awesome-icon class="text-lg text-blue-700" icon="pen"/>
          </button>
        </router-link>
        <button
          v-on:click='deleteIncomeModal(slotProps.data.id)'
          type='button'
          class='p-button-rounded p-button-plain p-mr-3'
        >
          <font-awesome-icon class="text-lg text-blue-700" icon="trash-alt"/>
        </button>
      </template>
    </Column>
  </DataTable>
  <Paginator v-model:first="paginator.first" :rows="paginator.rows" :totalRecords="storeIncomes.count" @page="onPage($event)"></Paginator>
</template>

<script>
// import { ref } from 'vue'
import { computed, onMounted, ref } from 'vue'
import { useStore } from 'vuex'
import useIncome from '../../../hooks/useIncome'
// import { useRoute } from 'vue-router'

export default {
  name: 'homeTabsIngresosTable',
  setup () {
    const store = useStore()
    const storeIncomes = computed(() => store.getters['storePettyCash/getIncomes'])
    const storeDate = computed(() => store.getters['storePettyCash/getStoreDate'])
    const { deleteIncome, getIncomes } = useIncome()
    const paginator = ref({
      rows: 10,
      first: 0
    })

    const getIncomesSearch = async (filter) => {
      try {
        await getIncomes(filter)
      } catch (error) {
        console.error('Error al buscar ingreso', error)
      }
    }

    const deleteIncomeModal = async (id) => {
      await deleteIncome(id)
      await getIncomesSearch({ date: storeDate.value, query: { limit: paginator.value.rows, offset: 0 } })
    }

    const onPage = async (event) => {
      await getIncomesSearch({ date: storeDate.value, query: { limit: paginator.value.rows, offset: event.first } })
    }

    onMounted(async () => {

    })

    return {
      storeIncomes,
      paginator,
      deleteIncomeModal,
      onPage
    }
  }
}
</script>

<style scoped>

</style>
