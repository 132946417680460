<template>
  <div class="grid grid-cols-6 gap-2 place-content-start">
    <div class="flex flex-col">
      <span class="font-bold">Tipo de ingreso:</span>
      <Dropdown v-model="incomeType" :options="typesIncomesObject" optionLabel="name" optionValue="value" placeholder="Seleccione un tipo"/>
    </div>
    <div class="flex flex-col">
      <span class="font-bold">N° Documento:</span>
      <InputText type="text" placeholder="Digite el N° Documento" v-model="numberDoc" />
    </div>
    <div class="flex items-end justify-center">
      <Button icon="pi pi-search"
              class="mr-2 p-button-info"
              @click="search"
              v-tooltip.top="'Buscar'"
      />
      <Button label="Limpiar filtro"
              class="mr-2 p-button-info"
              @click="clearFilter"
      />
    </div>
    <div class="flex flex-col justify-end">
      <span class="font-bold">Total Ingresos:</span>
      <span>{{ $h.formatCurrency(storeTotalInc,2) }}</span>
    </div>
    <div class="flex flex-col justify-end">
      <span class="font-bold">Total Registros:</span>
      <span>{{ storeIncomes.count }}</span>
    </div>
    <div class="flex items-end justify-center">
      <Button label="Agregar Ingreso"
        v-if="$can('pharmasan.contabilidad.caja-menor.ver-caja-menor.access')"
        icon="pi pi-th-large"
        class="mr-2"
        @click="addIncome"
      />
    </div>
  </div>
</template>

<script>
import useIncome from '../../../hooks/useIncome'
import { computed, ref } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import { error } from '@/libs/mensajes'
import IncomeService from '../../../services/incomeServices'
import dayjs from 'dayjs'

export default {
  name: 'homeTabsIngresosHeader',
  setup () {
    const _incomeService = new IncomeService()
    const store = useStore()
    const { typesIncomesObject, getIncomes } = useIncome()
    const numberDoc = ref(null)
    const incomeType = ref(null)
    const storeDate = computed(() => store.getters['storePettyCash/getStoreDate'])
    const storeTotalInc = computed(() => store.getters['storePettyCash/getTotalInc'])
    const storeIncomes = computed(() => store.getters['storePettyCash/getIncomes'])
    const route = useRoute()
    const router = useRouter()

    const search = async () => {
      await getIncomes({ date: storeDate.value, incomeType: incomeType.value, numberDoc: numberDoc.value })
      console.log(route)
    }

    const clearFilter = () => {
      if (numberDoc.value !== null || incomeType.value !== null) {
        numberDoc.value = null
        incomeType.value = null
        getIncomes({ date: storeDate.value, incomeType: incomeType.value, numberDoc: numberDoc.value })
      }
    }

    const addIncome = async () => {
      const validate = await _incomeService.incomeValidate()
      if (!validate.status || storeDate.value === dayjs().format('YYYY-MM-DD')) {
        await router.push({ name: 'pharmasan.contabilidad.caja-menor.ver-caja-menor.ingresos.crear' })
      } else {
        error(`La caja del dia ${validate.date} no se ha cerrado`)
      }
    }

    return {
      numberDoc,
      incomeType,
      typesIncomesObject,
      storeTotalInc,
      storeIncomes,
      addIncome,
      search,
      clearFilter
    }
  }
}
</script>

<style scoped>

</style>
